<template>
  <div>
    <Wrapper class="max-w-3xl mb-5 flex flex-col items-start">
      <p class="mb-2 text-gray-500 font-bold">
        Selecciona la forma en la que se registrará
      </p>
      <a-radio-group v-model="value">
        <a-radio :value="1"> Registro usuario uno a uno </a-radio>
        <a-radio :value="2"> Registro masivo de entidades </a-radio>
      </a-radio-group>
    </Wrapper>
    <Wrapper v-if="value == 1" class="max-w-3xl">
      <div class="mb-8 text-left">
        <h3 class="text-gray-600 text-lg font-bold">
          Registro uno a uno de entidades vigiladas
        </h3>
        <p class="text-gray-400 font-bold">
          Ingrese la información del usuario que registrará de manera individual
        </p>
      </div>
      <a-form-model layout="vertical" :model="form" class="mx-auto max-w-md">
        <a-form-model-item label="Nombre abreviado de la entidad">
          <a-input
            v-model="form.name"
            placeholder="Ej: Banco de la républica"
          />
        </a-form-model-item>
        <a-form-model-item label="NIT">
          <div class="w-full flex justify-center items-center gap-2">
              <a-input-number
              v-model="form.nit1"
              style="width: 70%"
              :default-value="0"
              :min="0"
              :max="1000000000000000000"
              placeholder="NIT"
            />
            <span class="text-lg"><b>-</b></span>
            <a-input-number
              v-model="form.nit2"
              style="width: 30%"
              :default-value="0"
              :min="0"
              :max="9"
              placeholder="Dígito de verificación"
            />
          </div>
        </a-form-model-item>
        <a-form-model-item label="Código de la entidad">
          <a-input v-model="form.sfc_code" placeholder="número" />
        </a-form-model-item>
        <a-form-model-item label="Tipo de entidad" prop="region">
          <a-select
            v-model="form.company_type"
            placeholder="Seleccione el tipo"
          >
            <a-select-option
              v-for="company in formOptions.optionsCompanyType"
              :value="company.code"
              :key="company.code"
            >
              {{ company.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <div v-if="feedback">
          <p class="my-2 text-xs text-center text-red-500">{{ feedback }}</p>
        </div>
        <div class="w-full mt-6 flex flex-col md:flex-row gap-5">
          <a-button
            type="danger"
            @click="$router.go(-1)"
            shape="round"
            ghost
            :block="true"
            :loading="loading"
          >
            <span class="mx-2">CANCELAR</span>
          </a-button>
          <a-button
            type="primary"
            @click="createCompany"
            shape="round"
            :block="true"
            :loading="loading"
          >
            <span class="mx-2">GUARDAR</span>
          </a-button>
        </div>
      </a-form-model>
    </Wrapper>
    <Wrapper v-if="value == 2" class="max-w-3xl">
      <div class="mb-8 text-left">
        <h3 class="text-gray-600 text-lg font-bold">
          Registro masivo de entidades vigiladas
        </h3>
        <p class="text-gray-400 font-bold">
          Registra masivamente a múltiples usuarios en una sola carga
        </p>
      </div>
      <div class="mx-auto max-w-md">
         <DragDropExcel
        id="authorizationFiles"
        @files-handler="setCompanyFile"
        required="true"
        single="false"
        :fileList="authorizationFiles"
        buttonLabel="ADJUNTAR SU ARCHIVO DE CARGA"
      />
      <div class="w-full mt-8 flex flex-col md:flex-row gap-5">
        <a-button
          type="danger"
          @click="$router.go(-1)"
          shape="round"
          :block="true"
          ghost
          :loading="loading"
        >
          <span class="mx-2">CANCELAR</span>
        </a-button>
        <a-button
          type="primary"
          @click="createCompanyExcel"
          shape="round"
          :block="true"
          :loading="loading"
        >
          <span class="mx-2">GUARDAR</span>
        </a-button>
      </div>
      </div>
    </Wrapper>
    <ModalResponseMassive
      :visible="showModal"
      :list_created="list_created"
      @toggle-modal="toggleModal"
      :list_type_created="list_type_created"
      :num_exist="num_exist"
    />
  </div>
</template>

<script>
import Wrapper from "../../UI/Wrapper";
import ModalResponseMassive from "./ModalResponseMassive.vue";
export default {
  components: { Wrapper, ModalResponseMassive },
  data() {
    return {
      list_type_created: [],
      list_created: [],
      num_exist: 0,
      value: 1,
      showModal: false,
      authorizationFiles: [],
      companyFile: null,
      loading: false,
      feedback: "",
      form: {
        name: "",
        nit: "",
        nit1: "",
        nit2: "",
        sfc_code: "",
        company_type: "",
      },
    };
  },
  methods: {
    async createCompany() {
      if (!this.checkForm()) {
        return;
      }
      const test_nit = /(^[0-9]+-{1}[0-9]{1})/;
      if (!test_nit.test(`${this.form.nit1}-${this.form.nit2}`)) {
        this.showToast("error", "El nit no es válido para el formato usado.");
        return;
      }
      const myForm = {
        ...this.form,
        nit: `${this.form.nit1}-${this.form.nit2}`,
      };
      
      this.loading = true;
      let{data, error} = await this.$api.createCompany(myForm);
      this.loading = false;

      if (error) this.showToast("error", "Error creando la entidad.");

      if(data) {
        if (data.code === 401) {
            // NIT
            this.showToast("error", data.error);
          } else if (data.code === 402) {
            // Código y tipo de entidad
            this.showToast("error", data.error);
          } else {
            this.showToast("success", "Entidad creada correctamente.");
            this.$router.go(-1);
          }
      }
    },
    checkForm() {
      this.feedback = null;

      if (this.$validator.isEmpty(this.form.name)) {
        this.feedback = "Introduzca el nombre de la entidad.";
        return false;
      }
      if (this.$validator.isEmpty(this.form.nit1 + "")) {
        this.feedback = "Introduzca el número NIT";
        return false;
      }
      if (this.$validator.isEmpty(this.form.nit2 + "")) {
        this.feedback = "Introduzca el código de verificación NIT";
        return false;
      }
      if (this.$validator.isEmpty(this.form.sfc_code)) {
        this.feedback = "Introduzca un número para el código de la entidad.";
        return false;
      }
      if (this.$validator.isEmpty(this.form.company_type + "")) {
        this.feedback = "Seleccione el tipo de la entidad.";
        return false;
      }
      return true;
    },
    setCompanyFile(files) {
      let size = this.checkFileSize(files);
      if (size <= 30) {
        this.authorizationFiles = files;
        this.feedback = "";
      } else {
        this.feedback =
          "Con el archivo que intenta cargar, se supera el peso máximo permitido de los anexos. Valide la información.";
      }
    },
    checkFileSize(files) {
      let size = 0;
      files.forEach((item) => {
        size += item.size;
      });
      size = size / (1024 * 1024);
      return size;
    },
    async createCompanyExcel() {
      this.showModal = false;
      let formData = new FormData();
      formData.append(`file`, this.authorizationFiles[0]);
      
      this.loading = true;
      let{data, error} = await this.$api.createCompanyExcel(formData);
      this.loading = false;

      if (error) this.showToast("error", "Error guardando el archivo de las entidades.");

      if(data) {
        this.list_created = data.list_created;
        this.num_exist = data.num_exist;
        this.list_type_created = data.list_type_created;
        this.showModal = true;
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    toggleModal(val) {
      this.showModal = false;
      this.$router.go(-1);
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
  },
};
</script>