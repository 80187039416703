<template>
  <div>
    <a-modal :visible="visible" :closable="false" on-ok="handleOk" centered>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          CERRAR
        </a-button>
      </template>
      <div class="text-center">
        <h3 class="text-2xl text-blue-dark"><b>Resultado de la operación</b></h3>
        <br>
        <p>Se registraron {{list_created.length}} entidad(es)</p>
        <br>
        <p>No se registraron {{num_exist}} entidad(es) por duplicidad</p>
        <br>
        <p>Se registraron {{list_type_created.length}} tipo(s) de entidades</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    list_created: [],
    visible: Boolean,
    list_type_created: [],
    num_exist: Number
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleCancel(e) {
      this.$emit('toggle-modal', false);
    },
  },
};
</script>
