<template>
  <div>
      <AddCompanyForm></AddCompanyForm>
  </div>
</template>

<script>
import AddCompanyForm from '../../../components/Dashboard/Companies/AddCompanyForm'
export default {
  components: { AddCompanyForm },

}
</script>